import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';
import { keep2Decimal, keep2DecimalContainNegative, keepNotAloneNegative } from '@assets/js/regExUtil';

export const shTableProperties = [
  store.state.selection,
  store.state.index,
  {
    label: '收款单类别',
    prop: 'soll_status',
    itemType: 'select',
    options: [
      { value: 1, label: '收款单' },
      { value: 2, label: '预收款单' }
    ],
    input: true,
    sortable: false,
    formatter: val => (val === 1 ? '收款单' : val === 2 ? '预收款单' : '')
  },
  { label: '收款单号', prop: 'soll_no', itemType: 'input', input: true, sortable: false },
  {
    label: '收款日期',
    prop: 'soll_date',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: true,
    widthAuto: false,
    labelWidth: '170px',
    formatter: val => getDateNoTime(val, false)
  },
  { label: '出运发票号', prop: 'tran_nos', itemType: 'input', input: true, sortable: false },
  { label: '销售合同号', prop: 'scon_nos', itemType: 'input', input: true, sortable: false },
  { label: '客户订单号', prop: 'scon_cust_nos', itemType: 'input', input: true, sortable: false },
  { label: '汇款客户', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, overflowTooltip: true },
  { label: '汇款抬头', prop: 'cptt_aname', itemType: 'input', input: false, sortable: false },
  { label: '收款总额', prop: 'soll_total', itemType: 'input', input: false, sortable: false, align: 'right' },
  { label: '币种', prop: 'cust_currency', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '汇率', prop: 'tran_usdrate', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '银行费用', prop: 'soll_chartotal', itemType: 'input', input: false, sortable: false, align: 'right' },
  { label: '领用金额', prop: 'soll_claitotal', itemType: 'input', input: false, sortable: false, align: 'right' },
  { label: '发布金额', prop: 'soll_issu', itemType: 'input', input: false, sortable: false, align: 'right' },
  { label: '收款银行', prop: 'cptt_bank_name', itemType: 'input', input: true, sortable: false, overflowTooltip: true },
  { label: '收款抬头', prop: 'cptt_bname', itemType: 'input', input: true, sortable: false, overflowTooltip: true },
  store.state.stff_name,
  store.state.create_time,
  store.state.status
];
export const hkTableProperties = [
  store.state.selection,
  store.state.index,
  { label: '收款单号', prop: 'holl_no', itemType: 'input', input: true, sortable: false },
  {
    label: '收款日期',
    prop: 'holl_date',
    itemType: 'date',
    input: true,
    sortable: true,
    valueFormat: 'timestamp',
    widthAuto: false,
    labelWidth: '170px',
    formatter: val => getDateNoTime(val, false)
  },
  { label: '出运发票号', prop: 'tran_nos', itemType: 'input', input: true, sortable: false },
  { label: '销售合同号', prop: 'scon_nos', itemType: 'input', input: true, sortable: false },
  { label: '客户订单号', prop: 'scon_cust_nos', itemType: 'input', input: true, sortable: false },
  { label: '汇款客户', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, overflowTooltip: true },
  { label: '汇款抬头', prop: 'cptt_aname', itemType: 'input', input: false, sortable: false },
  { label: '收款总额', prop: 'holl_total', itemType: 'input', input: false, sortable: false, align: 'right' },
  { label: '币种', prop: 'cust_currency', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '汇率', prop: 'tran_usdrate', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '银行费用', prop: 'holl_chartotal', itemType: 'input', input: false, sortable: false, align: 'right' },
  { label: '领用金额', prop: 'holl_claitotal', itemType: 'input', input: false, sortable: false, align: 'right' },
  { label: '发布金额', prop: 'holl_issu', itemType: 'input', input: false, sortable: false, align: 'right' },
  { label: '收款银行', prop: 'cptt_bank_name', itemType: 'input', input: true, sortable: false, overflowTooltip: true },
  { label: '收款抬头', prop: 'cptt_bname', itemType: 'input', input: true, sortable: false, overflowTooltip: true },
  store.state.stff_name,
  store.state.create_time,
  store.state.status
];
export const sollMainFormProperties = [
  {
    label: '用友抓取状态',
    prop: 'yongYouStatus',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '收款类别',
    prop: 'soll_status',
    itemType: 'no',
    model: '',
    formatter: val => (val === 1 ? 'SH收款单' : val === 2 ? 'SH预收款单' : ''),
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    split: 24,
    show: true,
    disabled: false
  },
  {
    label: '汇款客户',
    prop: 'cust_abbr',
    itemType: 'input',
    inputAppend: '',
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '汇款抬头',
    prop: 'cptt_aid',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '收款日期',
    prop: 'soll_date',
    itemType: 'date',
    model: '',
    size: 'small',
    formatTime: 'yyyy-MM-dd',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '收款总额',
    prop: 'soll_total',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '收款抬头',
    prop: 'cptt_bid',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '币种',
    prop: 'cust_currency',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '银行费用',
    prop: 'soll_chartotal',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '收款银行',
    prop: 'cptt_bank_name',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '银行账号',
    prop: 'cptt_bank_account',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '发布金额',
    prop: 'soll_issu',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '领用金额',
    prop: 'soll_claitotal',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '汇率',
    prop: 'tran_usdrate',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '出运发票号',
    prop: 'tran_nos',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    split: 16,
    show: true,
    disabled: true
  },
  {
    label: '本币金额',
    prop: 'scon_rmbtotal',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    split: 8,
    show: true,
    disabled: true
  },
  {
    label: '销售合同号',
    prop: 'scon_nos',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    split: 12,
    show: true,
    disabled: true
  },
  {
    label: '客户订单号',
    prop: 'scon_cust_nos',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    split: 12,
    show: true,
    disabled: true
  }
];
export const sollSubTableProperties = [
  { label: '客户名称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '应收金额', prop: 'soll_subrece', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '已收金额', prop: 'soll_reced_total', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '未收款金额', prop: 'soll_unreced', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '本次收款金额',
    prop: 'soll_reced',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'input',
      input: (val, row) => (val ? (row.soll_reced = keep2Decimal(val)) : (row.soll_reced = 0)),
      maxLength: 13,
      clearable: false,
      disabled: row => row.operation_status === 1
    }
  },
  {
    label: '银行费用',
    prop: 'soll_rece_char',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'input',
      input: (val, row) => (val ? (row.soll_rece_char = keep2Decimal(val)) : (row.soll_rece_char = 0)),
      maxLength: 13,
      clearable: false,
      disabled: row => row.operation_status === 1
    }
  },
  {
    label: '收款差额',
    prop: 'soll_diffprice',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'input',
      input: (val, row) => (val ? (row.soll_diffprice = keep2DecimalContainNegative(val)) : (row.soll_diffprice = 0)),
      change: (val, row, options) => (row.soll_diffprice = keepNotAloneNegative(row.soll_diffprice)),
      maxLength: 13,
      clearable: false,
      disabled: row => row.operation_status === 1
    }
  },
  { label: '实收金额', prop: 'soll_netrece', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '备注',
    prop: 'soll_rece_remark',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'popoverInput', maxLength: 100, disabled: row => row.operation_status === 1 }
  },
  { label: '人民币金额', prop: 'soll_rmbprice', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const importSHTableProperties = [
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '应收款金额', prop: 'soll_subrece', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '已收金额', prop: 'soll_reced_total', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '收款抬头', prop: 'cptt_aname', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const hollMainFormProperties = [
  {
    label: '收款类别',
    prop: 'holl_status',
    itemType: 'no',
    model: '',
    formatter: val => (val === 1 ? 'HK收款单' : val === 2 ? 'HK预收款单' : ''),
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    split: 24,
    show: true,
    disabled: false
  },
  {
    label: '汇款客户',
    prop: 'cust_abbr',
    itemType: 'input',
    inputAppend: '',
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '汇款抬头',
    prop: 'cptt_aid',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '收款日期',
    prop: 'holl_date',
    itemType: 'date',
    model: '',
    size: 'small',
    formatTime: 'yyyy-MM-dd',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '收款总额',
    prop: 'holl_total',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '收款抬头',
    prop: 'cptt_bid',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '币种',
    prop: 'cust_currency',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '银行费用',
    prop: 'holl_chartotal',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '收款银行',
    prop: 'cptt_bank_name',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '银行账号',
    prop: 'cptt_bank_account',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '发布金额',
    prop: 'holl_issu',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '领用金额',
    prop: 'holl_claitotal',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '出运发票号',
    prop: 'tran_nos',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    split: 24,
    show: true,
    disabled: true
  },
  {
    label: '销售合同号',
    prop: 'scon_nos',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    split: 12,
    show: true,
    disabled: true
  },
  {
    label: '客户订单号',
    prop: 'scon_cust_nos',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    split: 12,
    show: true,
    disabled: true
  }
];
export const hollSubTableProperties = [
  { label: '客户名称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '应收金额', prop: 'holl_subrece', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '已收金额', prop: 'holl_reced_total', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '未收款金额', prop: 'holl_unreced', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '本次收款金额',
    prop: 'holl_reced',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'input',
      input: (val, row) => (val ? (row.holl_reced = keep2Decimal(val)) : (row.holl_reced = 0)),
      maxLength: 13,
      clearable: false,
      disabled: row => row.operation_status === 1
    }
  },
  {
    label: '银行费用',
    prop: 'holl_rece_char',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'input',
      input: (val, row) => (val ? (row.holl_rece_char = keep2Decimal(val)) : (row.holl_rece_char = 0)),
      maxLength: 13,
      clearable: false,
      disabled: row => row.operation_status === 1
    }
  },
  { label: '实收金额', prop: 'holl_netrece', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '备注',
    prop: 'holl_rece_remark',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'popoverInput', maxLength: 100, disabled: row => row.operation_status === 1 }
  }
];
export const importHKTableProperties = [
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '应收款金额', prop: 'holl_subrece', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '已收金额', prop: 'holl_reced_total', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '收款抬头', prop: 'cptt_aname', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const importCostTableProperties = [
  store.state.selection,
  store.state.index,
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '应收款金额', prop: 'scon_fees_usd', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '费用类型',
    prop: 'expense_type',
    itemType: 'input',
    input: false,
    sortable: true,
    formatter: val => (val === 0 ? '佣金' : '其他')
  }
];

export const dialogTableProperties = [
  { label: '单据号', prop: 'soll_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '汇款客户', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '出运发票号', prop: 'tran_nos', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '收款抬头', prop: 'cptt_bname', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
