import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const sollAPI = {
  getSolls: topic.sollTopic + '/get_solls',
  getSollById: topic.sollTopic + '/get_soll_by_id',
  addSoll: topic.sollTopic + '/add_soll',
  editSoll: topic.sollTopic + '/edit_soll',
  deleteSollByIds: topic.sollTopic + '/delete_soll_by_ids',
  getSollListByRichMax: params => getNoCatch(`${topic.sollTopic}/getSollListByRichMax`, params),
  addSollByRichMax: params => postNoCatch(`${topic.sollTopic}/addSollByRichMax`, params)
};
