<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button-group class="vg_mr_8">
          <el-button :type="activeTableName === 'sh' ? 'primary' : ''" size="small" @click="switchTable('sh')">S H</el-button>
          <el-button :type="activeTableName === 'hk' ? 'primary' : ''" size="small" @click="switchTable('hk')">H K</el-button>
        </el-button-group>
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="录入开始日期"
          end-placeholder="录入结束日期"
        />
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange2"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="收款开始日期"
          end-placeholder="收款结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getTableData"> 查询</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="refresh()"> 刷新</el-button>
        <el-button class="vd_export" size="small" type="primary" @click="syncDialogVisible = true">同步老ERP</el-button>
      </div>
      <search-table
        v-if="activeTableName === 'sh'"
        :tableRowKey="'soll_id'"
        ref="multiTable"
        :data="tableData"
        :columns="shTableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        :show-summary="true"
        :summariesColumns="['soll_total', 'soll_chartotal', 'soll_claitotal', 'soll_issu']"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="row => dbClickJp(row, 's', 144)"
        @getTableData="getTableData"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
      <search-table
        v-if="activeTableName === 'hk'"
        :tableRowKey="'holl_id'"
        ref="multiTable"
        :data="tableData"
        :columns="hkTableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        :show-summary="true"
        :summariesColumns="['holl_total', 'holl_chartotal', 'holl_claitotal', 'holl_issu']"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="row => dbClickJp(row, 'h', 145)"
        @getTableData="getTableData"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
      <el-dialog :visible.sync="syncDialogVisible" title="同步老ERP" top="0" width="70%">
        <div class="flexHV">
          <span style="margin-left: auto">注意:搜索为精确搜索，搜索过后才显示内容 同步数据将自动生效不可更改</span>
        </div>
        <DynamicUTable
          ref="dialogTable"
          :columns="dialogTableProperties"
          :dbClickJp="dialogDbClick"
          :need-check-box="false"
          :need-search="true"
          :needPagination="false"
          :table-data="dialogTableData"
          @getTableData="getSyncTableData"
        ></DynamicUTable>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import { sollAPI } from '@api/modules/soll';
import { hollAPI } from '@api/modules/holl';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { dialogTableProperties, hkTableProperties, shTableProperties } from '@/views/FinanceManagement/SollManage/soll';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'SollList',
  components: {
    DynamicUTable,
    SearchTable,
    pubPagination
  },
  data() {
    return {
      activeTableName: 'sh',
      shTableProperties: shTableProperties,
      hkTableProperties: hkTableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      dateRange: [],
      dateRange2: [],
      syncDialogVisible: false,
      dialogTableData: [],
      dialogTableProperties: dialogTableProperties
    };
  },
  async mounted() {
    let { cust, type } = this.$route.query;
    if (type === 'se') {
      this.activeTableName = 'sh';
    } else if (type === 'he') {
      this.activeTableName = 'hk';
    }
    await (this.$refs.multiTable.searchForm.cust_abbr = cust);
    await this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/soll_edit' || from.path === '/holl_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getTableData();
    },
    async getTableData() {
      this.loadFlag = true;
      let searchForm = this.$refs.multiTable.searchForm;
      this.setDateRange(searchForm, this.dateRange, 'startTime', 'endTime');
      this.setDateRange(searchForm, this.dateRange2, 'startTime2', 'endTime2');

      try {
        let { data } = await getNoCatch(this.activeTableName === 'sh' ? sollAPI.getSolls : hollAPI.getHolls, searchForm);
        this.loading = false;
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadFlag = false;
      }
    },

    setDateRange(searchForm, dateRange, startKey, endKey) {
      let [start, end] = dateRange || [];
      searchForm[startKey] = start ? start / 1000 : null;
      searchForm[endKey] = end ? end / 1000 : null;
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row, region, permId) {
      this.jump(`/${region}oll_edit`, {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row[`${region}oll_id`]
          })
        )
      });
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //客户信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let ids = [];
          let activeNameBoolean = this.activeTableName === 'sh';
          if (activeNameBoolean) {
            ids = Array.from(this.multiSelection, ({ soll_id }) => soll_id);
          } else {
            ids = Array.from(this.multiSelection, ({ holl_id }) => holl_id);
          }
          let postBody = [{ soll_id_list: ids }, { holl_id_list: ids }];
          postNoCatch(activeNameBoolean ? sollAPI.deleteSollByIds : hollAPI.deleteHollByIds, postBody[activeNameBoolean ? 0 : 1]).then(() => {
            this.$message.success('删除成功!');
            this.refresh();
          });
        })
        .catch(() => this.$message.info('取消删除!'));
    },
    refresh() {
      this.dateRange = [];
      this.dateRange2 = [];
      this.$refs.multiTable.resetFields();
      this.initData();
    },
    async switchTable(pageName) {
      await (this.activeTableName = pageName);
      await this.$refs.multiTable.resetFields();
      await this.getTableData();
    },
    getSyncTableData() {
      let deep = cloneDeep(this.$refs.dialogTable.searchForm);
      delete deep.page_no;
      if (Object.values(deep).every(value => !value)) return;
      sollAPI.getSollListByRichMax(this.$refs.dialogTable.searchForm).then(({ data }) => {
        this.dialogTableData = data.list;
      });
    },
    dialogDbClick(row) {
      this.$confirm('确定同步？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          sollAPI.addSollByRichMax(row).then(() => {
            this.$message.success('同步成功!');
            this.syncDialogVisible = false;
            this.$refs.multiTable.searchForm.soll_no = row.soll_no;
            this.getTableData();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
